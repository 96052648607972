.summary-card-icon {
  margin-right: 10px;
}
.summary-card-title {
  font-size: 1rem;
  font-weight: 700;
}

.summary-card-value {
  font-size: 0.9rem;
}
.dought-legend-complete {
  width: 20px;
  height: 20px;
  background: rgba(20, 134, 102, 1);
  margin-right: 10px;
}

.dought-legend-in-progress {
  width: 20px;
  height: 20px;
  background: rgba(247, 148, 29, 1);
  margin-right: 10px;
}

.dought-legend-canceled {
  width: 20px;
  height: 20px;
  background: rgba(211, 76, 76, 1);
  margin-right: 10px;
}
