.side-bar {
  min-height: 100vh;
  background-color: #ffffff !important;
  box-shadow: 5px 0px 6px rgba(0, 0, 0, 0.25);
  position: relative;
}
.header-bar {
  display: flex;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
  overflow-x: hidden;
}
/* .active-page-container {
  background-color: #161a61;
  width: fit-content !important;
  padding-block: 0.7rem;
  padding-inline: 2.5em !important;
  border-radius: 0.5rem;
  color: #ffffff;
} */
.page-link-container {
  margin-inline: 3rem;
  padding-block: 0.8rem;
  border-radius: 0.5rem;
  /* color: #161a61; */
  color: rgb(14, 70, 100);
}
.link-name {
  margin-bottom: 0;
  margin-left: 1rem;
  font-size: 1.01em !important;
  font-weight: 600;
  text-decoration: none;
  /* color: #161a61; */
  color: rgb(14, 70, 100);
}
.side-bar-dropdown ul {
  box-shadow: none !important;
}

.link-btn {
  background-color: #ffffff !important;
}
.link-name {
  font-size: 14px;
}
.page_title {
  font-family: "Nexa-Black";
  font-weight: 500;
  font-size: 1.6em;
}
