@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.action-btn {
  background: #f7941d !important;
  color: #ffff;
  font-weight: 700;
}

.ant-dropdown-menu {
  box-shadow: none !important;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: rgb(14, 70, 100);
  color: #fff;
  padding-left: 30px;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  .ant-table-row
  .ant-table-cell {
  padding-left: 30px;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  .ant-table-row
  .ant-table-row-expand-icon-cell {
  padding-inline: 0;
}
