.action-btn {
  background: #f7941d !important;
  color: #ffff;
  font-weight: 700;
}
#message-header {
  text-align: center;
  font-weight: 700;
  font-size: 1.6rem;
  color: #161a61;
  margin-bottom: 0.2em;
}
#message-subtitle {
  color: #161a61;
  font-weight: 400;
  /* text-transform: capitalize; */
  font-size: 0.8rem;
  /* text-align: center; */
}
