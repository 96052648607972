.layout {
  position: relative;
  min-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout::before {
  content: "";
  background-image: url("../assets/bd-img.jpg");
  background-size: contain;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
}
.container {
  position: relative;
}

#card-header {
  text-align: center;
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 700;
  color: #161a61;
}

#page-title {
  text-align: center;
  margin-bottom: 5px;
}
